import React from 'react'
import Footer from '../../component/footer/Footer'
import './Resources.scss'

export default function Resources() {
  return (
    <>
  <section className="section-services">
		<div className="container">
			<div className="row justify-content-center text-center">
				<div className="col-md-10 col-lg-8">
					<div className="header-section">
						<h2 className="title">Resources <span>Development</span></h2>
						<p className="description">Because people and their relationships are at the heart of what we do. We all have emotions. We are interested, we listen and we understand. </p>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-md-6 col-lg-4">
					<div className="single-service">
						<div className="part-1">
							<i className="fab fa-500px"></i>
							<h3 className="title">Collaborative Spirit</h3>
						</div>
						<div className="part-2">
							<p className="description">Because we appreciate each other’s contributions and abilities whether we are working with a client to fill a role.</p>
							<a href="#"><i className="fas fa-arrow-circle-right"></i>Read More</a>
						</div>
					</div>
				</div>
				<div className="col-md-6 col-lg-4">
					<div className="single-service">
						<div className="part-1">
							<i className="fab fa-angellist"></i>
							<h3 className="title">For Recources</h3>
						</div>
						<div className="part-2">
							<p className="description">As wel as working with a candidate to find their ideal position or working with each other to share knowledge.</p>
							<a href="#"><i className="fas fa-arrow-circle-right"></i>Read More</a>
						</div>
					</div>
				</div>
				<div className="col-md-6 col-lg-4">
					<div className="single-service">
						<div className="part-1">
							<i className="fas fa-award"></i>
							<h3 className="title">Daringly Different</h3>
						</div>
						<div className="part-2">
							<p className="description">Because we are proud to take chances in order to help change peoples' lives and businesses for the better..</p>
							<a href="#"><i className="fas fa-arrow-circle-right"></i>Read More</a>
						</div>
					</div>
				</div>
				<div className="col-md-6 col-lg-4">
					<div className="single-service">
						<div className="part-1">
							<i className="fab fa-asymmetrik"></i>
							<h3 className="title">Adaptable and Dynamic</h3>
						</div>
						<div className="part-2">
							<p className="description">Our personal touch is at the heart of our business and stands us apart from our competitors. We are adaptable and dynamic. Time spent on hiring is well spent.</p>
							<a href="#"><i className="fas fa-arrow-circle-right"></i>Read More</a>
						</div>
					</div>
				</div>
				<div className="col-md-6 col-lg-4">
					<div className="single-service">
						<div className="part-1">
							<i className="fas fa-broadcast-tower"></i>
							<h3 className="title">Expertly Informed</h3>
						</div>
						<div className="part-2">
							<p className="description">Because everything we do comes from a position of experience and insight. Our long history in the industry affords us respect and permission to challenge the status quo.</p>
							<a href="#"><i className="fas fa-arrow-circle-right"></i>Read More</a>
						</div>
					</div>
				</div>
				<div className="col-md-6 col-lg-4">
					<div className="single-service">
						<div className="part-1">
							<i className="fab fa-canadian-maple-leaf"></i>
							<h3 className="title">Building Bonds</h3>
						</div>
						<div className="part-2">
							<p className="description"> We naturally want to learn, to be able to do the best for our customers. Also builds relationships and knows how to not only find candidates but also get them to respond!</p>
							<a href="#"><i className="fas fa-arrow-circle-right"></i>Read More</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>





<Footer />
    </>
  )
}
