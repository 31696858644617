import React from 'react';
import './Footer.scss';
// import Login from '../login/Login.js';


function Footer() {
  return (
    <>
    
    <footer>
    <div class="container-fluid p-5">
        <div class="row footer-gap">
            <div class="col-lg-5 mb-lg-0 mb-3">
                <h3 class="text-capitalize mb-3">About Company</h3>
                <p>Nabuz Software company provides next-generation digital services and
                    consulting.The company assists clients from more than 10 countries 
                    with their digital transformation. </p>
                <div class="row mt-4">
                    <div class="col-md-6">
                        <h3 class="text-capitalize mb-3">Connect With Us</h3>
                        <p><span class="fab fa-twitter"></span> <a href="#" target="_blank"> Linkedin/@Nabuz</a> </p>
                        <p><span class="fab fa-instagram"><a href="#" target="_blank"> Instagram/@nabuz</a></span></p>
                        <p><span class="fab fa-youtube mb-4"></span> youtube/@nabuz</p>
                        {/* <a href="#" class="facebook mr-2" target="_blank"><span class="fab mr-1 fa-facebook-f"></span> Facebook</a>
                        <a href="#" class="twitter"><span class="fab mr-1 fa-twitter"></span> Twitter</a> */}
                    </div>
                    <div class="col-md-6 mt-md-0 mt-5">
                        <h3 class="text-capitalize mb-3">Head Quarters</h3>
                        <address class="mb-0">
                            <p class="mb-2"><i class="fas fa-map-marker"></i> Shop No. -4, 1st Floor, Universal hight (Building) Brahmchari Nagar, Kanke Rd, Ranchi, Jharkhand 834008.</p>
                            <p><i class="fas mr-1 fa-clock"></i> Timings : 10 a.m to 7 p.m</p>
                            <p><i class="fas mr-1 fa-phone"></i> 9533415240</p>
                            {/* <p><i class="fas mr-1 fa-fax"></i>  </p>  */}
                            <p><i class="fas mr-1 fa-envelope-open"></i> <a href="mailto:info@nabuzsoftware.com">info@nabuzsoftware.com</a></p>
                        </address>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 mt-lg-0 mt-sm-0 p-md-0">
                <h3 class="text-capitalize mb-3">Services</h3>
                <ul>
                    <li><i class="fas mr-1 fa-chevron-circle-right"></i> Software development.</li>
                    <li><i class="fas mr-1 fa-chevron-circle-right"></i> IT Consultants.</li>
                    <li><i class="fas mr-1 fa-chevron-circle-right"></i> Web development.</li>
                    <li><i class="fas mr-1 fa-chevron-circle-right"></i> Data Security.</li>
                    <li><i class="fas mr-1 fa-chevron-circle-right"></i> Product Design.</li>
                    <li><i class="fas mr-1 fa-chevron-circle-right"></i> IT Management.</li>
                    <li><i class="fas mr-1 fa-chevron-circle-right"></i> Business solution.</li>
                    <li><i class="fas mr-1 fa-chevron-circle-right"></i> Digital Marketing.</li>
                </ul>
                <h3 class="text-capitalize mt-4 mb-3">Newsletter</h3>
                <p class="mb-3">Subscribe to Our Newsletter to get News, Amazing Offers &amp; More</p>
                <form action="#" method="post">
                    <input type="email" name="Email" placeholder="Enter your email..." required="" />
                    <button class="btn1"><i class="far fa-paper-plane"></i></button>
                    <div class="clearfix"> </div>
                </form>
            </div>


            <div className="footer-base-container" >
            <div className="footercontent">
                <div className="form_login_footer">
                    <div className="form-group">
                        <label htmlFor="username">Username</label>
                        <input type="text" name="username" placeholder="username" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <input type="password" name="password" placeholder="password" />
                    </div>
                </div>
            </div>
            <div className="footer">
                <button type="button" className="login_btn">
                    Login
                </button>
            </div>
        </div>

        </div>
    </div>
    <div class="copyright pb-sm-5 pb-3 text-center">
        <p>
            Copyright © 2022 <a href="/">Nabuz Software</a> All Rights Reserved
        </p>
    </div>
</footer>
    </>
  )
}

export default Footer;