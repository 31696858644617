import React, { useState } from "react";
import "./Navbar.css"

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { FaFacebook } from '@fortawesome/free-solid-svg-icons'
import { FaFacebook, FaInstagram, FaTwitter, FaGithub, FaHamburger, FaTimes, FaWhatsapp } from "react-icons/fa";
import { Link } from "react-router-dom";
import nabuz from '../../images/nabuz.png'
// import react, {Service}  from react;

const Navbar = () => {

    const [showNav, setShowNav] = useState(false);

    return (
        <>
            <div className="nav_body1">
                {/* <div className={showNav ? "nav_body1" : "nav_body2"}> */}

                <Link to="/" className="logo"><img className="logo" alt=''src={nabuz}/></Link>
                <div className={showNav ? "nav_body2" : "nav_body3"}
                    onClick={() => setShowNav(false)}>
                    {/* <div className="nav_body"> */}
                    <ul className="nav_item">
                        <Link to="/" className="home">
                            <li>Home</li>
                        </Link>
                        <Link to="/service" className="service">
                            <li>Service</li>
                        </Link>
                        <Link to="/resources" className="resources">
                            <li>Resources</li>
                        </Link>
                        <Link to="/about" className="about">
                            <li>About</li>
                        </Link>
                    </ul>
                    <Link to="/signin" className="signin">
                        <button className="noselect">Sign In</button>
                    </Link>
                    <ul className="wrapper">
                        <li className="icon facebook">
                            <span> <a href="https://www.facebook.com" title=""><FaFacebook className="icon facebook" /> </a>
                            </span>
                        </li>
                        <li className="icon twitter">
                            <span><a href="https://twitter.com" title=""><FaTwitter className="icon twitter" /></a>
                            </span>
                        </li>
                        <li className="icon instagram">
                            <span><a href="https://www.instagram.com" title=""><FaInstagram className="icon instagram" /></a>
                            </span>
                        </li>
                        <li className="icon github">
                            <span><a href="https://www.githun.com" title=""><FaGithub className="icon github" /></a>
                            </span>
                        </li>
                        {/* <li className="icon youtube">
                            <span><a href="https://www.youtube.com" title=""><FaYoutube className="icon youtube" /></a>
                            </span>
                        </li> */}
                        <li className="icon whatsapp">
                            <span><a href="https://web.whatsapp.com" title=""><FaWhatsapp className="icon whatsapp" /></a>
                            </span>
                        </li>
                    </ul>
                    {/* <FaHamburger className="hamburger" /> */}
                </div>
                <div className="fahamrt" onClick={() => setShowNav(!showNav)}>
                    {showNav ? (<FaTimes className="fatimes" />)
                        :
                        (<FaHamburger className="hamburger" />)}
                </div>
            </div>


        </>
    );

}
export default Navbar;