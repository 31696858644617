import React from 'react'
import './Cardthree.scss'
// import fontawesome from '@fortawesome/fontawesome'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cardthreeimg1 from '../../images/cardthreeimg1.png'
import cardthreeimg2 from '../../images/cardthreeimg2.png'
import cardthreeimg3 from '../../images/cardthreegif3.gif'
import cardthreeimg4 from '../../images/cardthreeimg4.png'
import cardthreeimg5 from '../../images/cardthreeimg5.png'
import cardthreeimg6 from '../../images/cardthreegif2.gif'



export default function Cardthree() {
    return (
        <>
            <div className='cardthree_body'>
                <div className='containerrr'>
                    <div>
                        <div className='cardthree_thirdbody'>
                            <div className='imagee'>
                                <img className='imagee2' src={cardthreeimg6} alt="" />
                            </div>
                            <div className='contentee'>
                                <h3>STEP ONE</h3>
                                <p>We don't want to push our ideas to customers. We simply want to make what they want.</p>

                                {/* <p>Software development has emerged as a crucial factor
                                    that may guarantee any enterprise’s success. It catalyzes
                                    the expansion of any type of business in today’s world.</p> */}
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className='cardthree_thirdbody'>
                            <div className='imagee'>
                                <img className='imagee2' src={cardthreeimg5} alt="" />
                            </div>
                            <div className='contentee'>
                                <h3>STEP TWO</h3>
                                <p>Good fortune is what happens when opportunity meets with proper planning.</p>

                                {/* <p>Software development has emerged as a crucial factor
                                    that may guarantee any enterprise’s success. It catalyzes
                                    the expansion of any type of business in today’s world.</p> */}
                            </div>
                        </div>
                        </div>
                        <div>
                        <div className='cardthree_thirdbody'>
                     
                            <div className='imagee'>
                                <img className='imagee2' src={cardthreeimg4} alt="" />
                            </div>
                            <div className='contentee'>
                                <h3>STEP THREE</h3>
                                <p>Continuous improvement is better than delayed perfection.</p>

                                {/* <p>Software development has emerged as a crucial factor
                                    that may guarantee any enterprise’s success. It catalyzes
                                    the expansion of any type of business in today’s world.</p> */}
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className='cardthree_thirdbody'>
                            <div className='imagee'>
                                <img className='imagee2' src={cardthreeimg3} alt="" />
                            </div>
                            <div className='contentee'>
                                <h3>STEP FOUR</h3>
                                <p>There are no good or bad experiences – just learning experiences.</p>
{/* 
                                <p>Software development has emerged as a crucial factor
                                    that may guarantee any enterprise’s success. It catalyzes
                                    the expansion of any type of business in today’s world.</p> */}
                            </div>
                        </div>
                        </div>   
                </div>
            </div>
    


{/* how we work  */}


<section class="work">
    <div class="work-layer py-5">
        <div class="containerr py-md-3">
            <h3 class="heading mb-lg-5 mb-4">A four step process</h3>
            <div class="row join agile-info">
                <div class="col-md-3 col-sm-6 steps-reach w3-agile-grid">
                <FontAwesomeIcon icon="fa-brands fa-algolia" />
                    <h4>SCREENING</h4>
                    <p>The purpose of screening a resume is to determine if the applicant has the basic knowledge and skills needed to do the job you’re trying to fill.</p>
                    <div class="style-border">
                        {/* <img className='stepsImg' src="https://tiyashasoftware.com/assets/images/sty1.png" alt="" /> */}
                    </div>
                </div>
                <div class="col-md-3 col-sm-6 mt-sm-0 mt-5 steps-reach w3-agile-grid">
                <FontAwesomeIcon icon="fa-brands fa-algolia" />                    
                <h4>INTERVIEWING</h4>
                    <p>There are three crucial elements that a candidate must possess in order to succeed within a job: knowledge, skills and attitude. From resume screening, you can usually determine if a candidate has the basic knowledge and skills to do the job.</p>
                    <div class="style-border second-border">
                        {/* <img className='stepsImg' src="https://tiyashasoftware.com/assets/images/sty2.png" alt="" /> */}
                    </div>
                </div>
                <div class="col-md-3 col-sm-6 mt-md-0 mt-5 pt-md-0 pt-sm-5 steps-reach w3-agile-grid">
                <FontAwesomeIcon icon="fa-brands fa-algolia" />                    
                <h4>TESTING</h4>
                    <p>Administering a test may help in understanding if the candidate is qualified, skilled enough or has the abilities to perform the job for which you are hiring.</p>
                    <div class="style-border">
                        {/* <img className='stepsImg' src="https://tiyashasoftware.com/assets/images/sty2.png" alt="" /> */}
                    </div>
                </div>
                <div class="col-md-3 col-sm-6 mt-md-0 mt-5 pt-md-0 pt-sm-5 steps-reach w3-agile-grid">
                <FontAwesomeIcon icon="fa-brands fa-algolia" />                    
                <h4>REFERENCE   </h4>
                    <p>Check at least two business references on each finalist. Research shows that up to one-third of applicants embellish their employment history.</p>
                </div>
            </div>
        </div>
    </div>
</section>



   
        </>
    )
}
