import React, { useState } from 'react';
import './Signup.scss';
import admin from '../../images/admin.png';


function Signup() {

    const [firstName, setFirstName] = useState(null);
    const [lastName, setLastName] = useState(null);
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [confirmPassword, setConfirmPassword] = useState(null);

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        if (id === "firstName") {
            setFirstName(value);
        }
        if (id === "lastName") {
            setLastName(value);
        }
        if (id === "email") {
            setEmail(value);
        }
        if (id === "password") {
            setPassword(value);
        }
        if (id === "confirmPassword") {
            setConfirmPassword(value);
        }

    }

    const handleSubmit = () => {
        console.log(firstName, lastName, email, password, confirmPassword);
        alert('Signing Completed')
    }

    return (
        <div className="base-container">
            <div className="header">Register</div>
            <div className="content">
            {/* <div className="image_logi">
                    <img src={admin} alt='admin' className="image_signup" />
                </div> */}
                <div className="form_login">
                    <div className="form-group">
                        <label className="form__label" for="firstName">First Name </label>
                        <input type="text" name="" id="firstName" value={firstName} className="form__input" onChange={(e) => handleInputChange(e)} placeholder="FirstName" />
                    </div>
                    <div className="form-group">
                        <label className="form__label" for="lastName">Last Name </label>
                        <input type="text" name="" id="lastName" value={lastName} className="form__input" onChange={(e) => handleInputChange(e)} placeholder="LastName" />
                    </div>
                    <div className="form-group">
                        <label className="form__label" for="email">Email </label>
                        <input type="email" id="email" className="form__input" value={email} onChange={(e) => handleInputChange(e)} placeholder="Email" />
                    </div>
                    <div className="form-group">
                        <label className="form__label" for="password">Password </label>
                        <input className="form__input" type="password" id="password" value={password} onChange={(e) => handleInputChange(e)} placeholder="Password" />
                    </div>
                    <div className="form-group">
                        <label className="form__label" for="confirmPassword">Confirm Password </label>
                        <input className="form__input" type="password" id="confirmPassword" value={confirmPassword} onChange={(e) => handleInputChange(e)} placeholder="Confirm Password" />
                    </div>
                </div>
            </div>
            <div class="footer">
                <button onClick={() => handleSubmit()} type="submit" className="login_btn">Sign In</button>
            </div>
        </div>

    )
}

export default Signup;