import React from 'react';
import Footer from '../../component/footer/Footer';
import './Service.scss'

const Service = () => {
    return (
        <>
            <section class="service-grid">
            <div class="serviceContainer">
                <div class="row">
                    <div class="col-xl-12 text-center mb-4">
                        <div class="service-title">
                            <h4>Our Services</h4>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4 col-md-6 text-center mb-3">
                        <div class="service-wrap">
                            <div class="icon-service">
                                {/* <i class="fas fa-layer-group"></i> */}
                            </div>
                            <h4>Highly Recomended Services</h4>
                            <p>Providing a service that’s highly recommended by clients and candidates alike...</p>
                            {/* <a href="#">Read More</a> */}
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 text-center mb-3">
                        <div class="service-wrap">
                            <div class="icon-service">
                                {/* <i class="far fa-chart-bar"></i> */}
                            </div>
                            <h4>Highly customizable</h4>
                            <p>Challenging the recruitment industry's reputation for bad practice and poor service, and determined to epitomise a company that truly looks after its staff.</p>
                            {/* <a href="#">Read More</a> */}
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 text-center mb-3">
                        <div class="service-wrap">
                            <div class="icon-service">
                                {/* <i class="fas fa-database"></i> */}
                            </div>
                            <h4>Smart Work</h4>
                            <p>we work Smart to do one simple thing: to make people happy and fulfilled in their careers today so that we’re inspired to do it again tomorrow..</p>
                            {/* <a href="#">Read More</a> */}
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 text-center mb-3">
                        <div class="service-wrap">
                            <div class="icon-service">
                                {/* <i class="fas fa-cogs"></i> */}
                            </div>
                            <h4>service & plugins</h4>
                            <p>Real commitment to coaching and supporting our candidates before, during and after placements..</p>
                            {/* <a href="#">Read More</a> */}
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 text-center mb-3">
                        <div class="service-wrap">
                            <div class="icon-service">
                                {/* <i class="fas fa-chart-pie"></i> */}
                            </div>
                            <h4>Dedicated support</h4>
                            <p>As a client you won’t be inundated with CVs we’re fussy on your behalf, searching for the right person for your role and culture..</p>
                            {/* <a href="#">Read More</a> */}
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 text-center mb-3">
                        <div class="service-wrap">
                            <div class="icon-service">
                                {/* <i class="fas fa-thumbs-up"></i> */}
                            </div>
                            <h4>Staff Turnover</h4>
                            <p>Maintaining a unique and positive culture that promotes low staff turnover, based on our core values.</p>
                            {/* <a href="#">Read More</a> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
<Footer />
        </>
    )
}
export default Service;
