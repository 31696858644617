import React from 'react'
import Body from '../../component/body/Body';
import Grid from '../../component/grid/Grid';
// import Maincard from '../../component/grid/Maincard';
import Upload from '../../component/upload/Upload';
import Logo from '../../component/logo/Logo';
import Cardtwo from '../../component/cardtwo/Cardtwo.js';
// import Login from '../../component/login/Login';
import Cardthree from '../../component/cardthree/Cardthree';
import Footer from '../../component/footer/Footer';
// import FileUploader from '../../component/upload/FileUploader';

const Home = () => {
    return (
        <>
            <Body />
            <Upload />
            {/* <FileUploader /> */}
            <Logo />
            <Grid />
            {/* <Maincard /> */}
            <Cardtwo />
            <Cardthree />
            {/* <Login /> */}
            <Footer />
        </>
    )
}
export default Home;