import React from "react";
import Footer from "../../component/footer/Footer";
import './About.css';
// import { Container, Row, Col } from 'react-grid-system';
// import { Visible } from 'react-grid-system';
// import { setConfiguration } from 'react-grid-system';

// setConfiguration({ maxScreenClass: 'xl' });
// import GridLayout from "react-grid-layout";
// import "../images/bg.jpg";
// import { Responsive as ResponsiveGridLayout } from "react-grid-layout";
// const bg = new URL("../../images/bg.jpg", import.meta.url);

const About = () => {
    return (
        <>
            <div className="about1" >
                <div id="bg">
                    <img src="../../images/aboutbg1.html" alt="" />
                </div>
                <div className="aboutText1">
                    <h1 className="aboutText2">More flexibility. A job you love. A new career.</h1>

                    <p className="aboutPara1"><span style={{color: "#3a4660", fontSize : '2rem'}}>Nabuz Software</span> is one of the best promising human resource firm. It offers end-to-end manpower recruitment solutions such as permanent recruitment and Contract Staffing. Starting from management trainee / SE to functional heads/ Executive Directors.
 We are one of the promising <span style={{color: "#F7B733 ", fontSize : '2rem'}}>Software and IT Resource</span> provider companies in India.
We align ourselves with our customers as partners to assist them in achieving their goals and objectives. We have a reputation as a premium vendor, especially in the area of recruitment solutions. Our entire staffs are ready to work with clients. Our first and foremost objective is to enhance our partnership with our resources.
I consent to receiving emails and personalized ads. We select our clients very carefully. Our distinguished record with our customers sets us apart from all competition. Our clients act as our partners, we work together to solve the immediate and long-term goals.
We always want to listen to our unique solution, after that we work to locate people who have the skills required for their success at work.  </p>
                </div>
                {/* <h1 className="text_1">More flexibility. A job you love. A new career.</h1> */}
            </div>
            <Footer />
        </>
    );
}
export default About;

