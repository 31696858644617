
import './App.css';
import Navbar from './component/navbar/Navbar';
// import Body from './body/Body';
// import Grid from './grid/Grid';
// import Logo from './logo/Logo';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Service from './pages/service/Service';
import Home from './pages/home/Home';
// import Login from './component/login/Login';
import Signup from './component/signup/Signup';
import About from './pages/about/About';
import Resources from './pages/resources/Resources';


function App() {
  return (

    <div className="App">
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />}>
          </Route>
          <Route path="service" element={<Service />}>
          </Route>
          <Route path="resources" element={<Resources />}>
          </Route>
          <Route path="signin" element={<Signup />}>
          </Route>
          <Route path="about" element={<About />}>
          </Route>
        </Routes>
      </Router>

    </div>


  );
}

export default App;
