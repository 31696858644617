import React from 'react'
import img1 from '../../images/learningmanagement.gif'
import img2 from '../../images/schoolmanagement.gif'
import img3 from '../../images/crmmanagement.gif'
import img4 from '../../images/hospitalmanagement.gif'
import img5 from '../../images/assetmanagement.gif'
import img6 from '../../images/businessmanagement.gif'

function Cardtwoone() {
  return (
    <>
    
    <section class="blog blog-page">
    <div class="workd w3-agile py-5">
        <div class="container py-md-3">
            <h2 class="heading mb-lg-5 mb-4">We Produce</h2>
            <div class="row blog-grids">
                <div class="col-lg-6 w3-agile-grid">
                    <div class="row blog-grid1 mr-lg-1">
                    <br/>
                        <div class="col-lg-5 col-md-4 col-sm-5 pl-0">
                            <img src={img1} class="img-fluid" alt="" />
                        </div>
                        <p class="mt-2">Berif intro..</p>
                        <div class="col-lg-7 col-md-8 agile-info col-sm-7 blog-text">
                        <br/>
                            <h4>Point of Sale</h4>
                            <p>An important part of a point of purchase is the Point of Sale (POS), 
                            which is the place where customers make payments for goods and services 
                            and where sales taxes may be due. A physical sales point can be a POS terminal 
                            or system in a store, or a virtual sales point such as a computer or mobile device.</p>
                           
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 w3-agile-grid mt-lg-0 mt-5">
                    <div class="row blog-grid2 ml-lg-1">
                    <br/>
                        <div class="col-lg-5 col-md-4 col-sm-5 pl-0">
                            <img src={img2} class="img-fluid" alt="" />
                        </div>
                        <p class="mt-2">Berif intro..</p>
                        <div class="col-lg-7 col-md-8 agile-info col-sm-7 blog-text">
                        <br/>
                            <h4>School Management Softwares</h4>
                            <p>A School management framework is a cloud-based instructive ERP programming that empowers advanced education establishments and schools to oversee online confirmation, understudy enlistment, understudies' participation, online expenses, grades, tasks, library books, and so on.</p>
                            
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 w3-agile-grid mt-5">
                    <div class="row blog-grid1 mr-lg-1">
                    <div class="col-lg-5 col-md-4 col-sm-5 pr-0">
                            <img src={img3} class="img-fluid" alt="" />
                        </div>
                        <p class="mt-2">Berif intro..</p>
                        <div class="col-lg-7 col-md-8 agile-info col-sm-7 blog-text">
                            <h4>CR Management Softwares </h4>
                            <p>CRM is a technological solution for managing all your company's interactions and relationships with customers and potential customers. This solution improves your business relationships. CRM systems help companies streamline processes, improve profitability, and stay connected with their customers. A CRM system, such as Microsoft Dynamics CRM, helps with contact management, sales management, productivity, and more.</p>
                            
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 w3-agile-grid mt-5">
                    <div class="row blog-grid2 ml-lg-1">
                    <div class="col-lg-5 col-md-4 col-sm-5 pr-0">
                            <img src={img4} class="img-fluid" alt="" />
                        </div>
                        <p class="mt-2">Berif intro..</p>
                        <div class="col-lg-7 col-md-8 col-sm-7 agile-info blog-text">
                            <h4>Hospital Management Softwares</h4>
                            <p>Hospital management is known as the management or organization of hospitals. 
                            Hospital Management gives the immediate association between medical care administrations 
                            and those providing the administrations they require. A hospital Management Career includes 
                            guaranteeing powerful utilization of physical and monetary assets of the hospital.
                             Great hospital management can be the contrast between a very much kept up with and working 
                             hospital in a turbulent climate where the nature of patient consideration endures.</p>
                           
                        </div>
                    </div>
                </div>
                 <div class="col-lg-6 w3-agile-grid mt-5">
                    <div class="row blog-grid1 mr-lg-1">
                        <div class="col-lg-5 col-md-4 col-sm-5 pl-0">
                            <img src={img5} class="img-fluid" alt="" />
                        </div>
                        <p class="mt-2">Berif intro..</p>
                        <div class="col-lg-7 col-md-8 col-sm-7 agile-info blog-text">
                           
                            <h4>Asset Management Softwares</h4>
                            <p>It track and monitor every asset in your company in one central location
Manage your assets from any location, especially if the package has a mobile feature
Identify and address potential risks to your assets to minimize or avoid them immediately
Provide details about an asset’s lifecycle
Identify ghost assets to be removed from the system
Plan maintenance schedules for each asset to prevent service disruptions
Provides you with accurate, in-depth reports and audits quickly</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 w3-agile-grid mt-5">
                    <div class="row blog-grid2 ml-lg-1">
                        <div class="col-lg-5 col-md-4 col-sm-5 pl-0">
                            <img src={img6} class="img-fluid" alt="" />
                        </div>
                        <p class="mt-2">Berif intro..</p>
                        <div class="col-lg-7 col-md-8 col-sm-7 agile-info blog-text">
                            {/* <p class="mt-2">March 05 2020</p> */}
                            <h4>Business management Softwares </h4>
                            <p>Including CRMSs other business management softwares project & task management
time management & calendar
document sharing & collaboration
sales & budget, invoice, and expense management
business intelligence and dashboards
accounting and financial reporting
resource management..there are various functionalities you might need. But how to put together a fully integrated system, which pulls and pushes your data and enables you to have complete control over your business with 
only a few clicks? The answer is – you don’t have to do it by yourself, choose an end-to-end solution.</p>
                        
                        </div>
                    </div>
                </div> 
            </div>
        </div>
    </div>
</section>
    
    </>
  )
}



const Cardtwo = () => {
    return (
        <>
            <div classNameName='cards_cardtwo'>
                <Cardtwoone
                    title='We serve our best'
                    author="NABUZ SOFTWARE" />
            </div>

        </>
    )
}

export default Cardtwo;