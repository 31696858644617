import React from "react";
import './Body.css';

const Body = () => {
    return (
        <>
            <div className="body1" >

                <div className="text_1">
                    <h1 className="text_2">More flexibility. A job you love. A new career.</h1>

                    <p className="para-1">We've been placing great people in permanent & contract web developing, front-end
                        back-end, and all kind of software development roles since 2019 in india and all over the world.
                    </p>
                    <p className="para-2">
                        Whether you're starting your career in IT sector or switching focus, our consultants have invaluable insights for the roles 
                        we recruit for and will stand behind you at every stage of your career.
                    </p>
                </div>
            </div>
        </>
    );
}
export default Body;

