import React from "react";
// import { useForm } from "react-hook-form";
// import { Button, Form, Input } from "reactstrap";
// import { Container, Row, Col } from 'react-grid-system';
import './Upload.css';

const Upload = () => {
    // const { register, handleSubmit } = useForm();

    // const submitData = (data) => {
    //     console.log(data);
    // };

    // const { ref, ...field } = register("file");

    return (
    

        <div className="row">
            {/* <button className="colu-1" >
                <Form onSubmit={handleSubmit(submitData)}>
                    <div className="col1">
                        <Input {...field} innerRef={ref} type="file" id="file" />
                        <Button className="fileupload_button" type="submit" color="primary">
                            Submit
                        </Button>
                    </div>
                </Form>
            </button>
            <button className="colu-1 second" >
                <Form onSubmit={handleSubmit(submitData)}>
                    <div className="col1">
                        <Input {...field} innerRef={ref} type="file" id="file" />
                        <Button className="fileupload_button" type="submit" color="primary">
                            Submit
                        </Button>
                    </div>
                </Form>
            </button> */}
            <div>
                <h3 className='title-logo' >WE RECRUIT FOR</h3>
                <div className='title-logo-underline' ></div>
            </div>
        </div>
    )
};
export default Upload;
