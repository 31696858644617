
// -----THIRD CAROUSEL------
import './Logo.css';
import React from 'react';
import InfiniteCarousel from 'react-leaf-carousel';
// import { Container } from 'reactstrap';
import bg11 from '../../images/bg11.jpg'
import bg12 from '../../images/bg12.png'
import bg13 from '../../images/bg13.jpg'
import bg14 from '../../images/bg14.png'
import bg15 from '../../images/bg15.png'
import bg16 from '../../images/bg16.png'
import bg17 from '../../images/bg17.png'
import bg18 from '../../images/bg18.png'
import bg19 from '../../images/bg19.jpg'
import bg20 from '../../images/bg20.png'
import bg21 from '../../images/bg21.png'
import bg22 from '../../images/bg22.png'
import bg23 from '../../images/bg23.jpg'
import bg24 from '../../images/bg24.png'
import bg25 from '../../images/bg25.jpg'
import bg26 from '../../images/bg26.png'
import bg27 from '../../images/bg27.jpg'
import bg28 from '../../images/bg28.png'
import bg29 from '../../images/bg29.jpg'
import bg30 from '../../images/bg30.png'

const images = [
  bg11,
  bg12,
  bg13,
  bg14,
  bg15,
  bg16,
  bg17,
  bg18,
  bg19,
  bg20,
  bg21,
  bg22,
  bg23,
  bg24, bg25, bg26, bg27, bg28, bg29, bg30];

const Logo = () => {
  return (
    <>
      <div className='auto-slider'>

        <InfiniteCarousel

          breakpoints={[
            {
              breakpoint: 500,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
              },
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                autoplay: true,
                autoplaySpeed: 1000,
                infinite: true,
              },
            },
          ]}
          dots={true}
          // showSides={false}
          sidesOpacity={.5}
          // sideSize={.1}
          slidesToScroll={4}
          slidesToShow={4}
          scrollOnDevice={true}
          autoCycle={true}
          cycleInterval={5000}
          pauseOnHover={true}
        // arrows={false}
        >

          {
            images.map((each, index) => <img className='image-slider' key={index} style={{ width: "10rem", height: '7rem', margin: '1rem 4rem 1rem 5rem', position: 'relative', top: '0rem' }} src={each} alt="" />)
          }
        </InfiniteCarousel>
      </div>



    </>
  )
}
export default Logo;



